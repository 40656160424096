export default class Runners {
	constructor(gs1, gs81, gs131, mne70, mne125, zar12, zar96, zar170, discord_name, src_name, score, team) {
		this.gs1 = gs1
		this.gs81 = gs81
		this.gs131 = gs131
		this.mne70 = mne70
		this.mne125 = mne125
		this.zar12 = zar12
		this.zar96 = zar96
		this.zar170 = zar170
		this.discord_name = discord_name
		this.src_name = src_name
		this.score = score
		this.team = team
	}
}