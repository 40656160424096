export default class Runners {
	constructor(dl150, dlabs, dl80, za290, za2warpless, za2any5, eureka100, eureka60, eureka5, discord_name, src_name, score, team) {
		this.dl150 = dl150
		this.dlabs = dlabs
		this.dl80 = dl80
		this.za290 = za290
		this.za2warpless = za2warpless
		this.za2any5 = za2any5
		this.eureka100 = eureka100
		this.eureka60 = eureka60
		this.eureka5 = eureka5
		this.discord_name = discord_name
		this.src_name = src_name
		this.score = score
		this.team = team
	}
}